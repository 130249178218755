$main-bg: #151617;
$secondary-bg: #1c1e20;
$card-bg: #2c2F32;
$light-card-bg: #34383B;
$text-white: #FFFFFF;
$text-secondary: lighten(#96959A, 30%);
$text-subtle: lighten(#5A5C62, 30%);
$link-color: #389BFF;
$link-hover-color: #68A8E9;

$light-gray-1: #9D9FA2;
$light-gray-2: #D4D7DA;
$light-gray-3: #E7EAEE;
$dark-gray-5: #575A5D;

$grape-50: #E7F9FD;
$grape-100: #ECDCFB;
$grape-200: #D7BEF8;
$grape-300: #B997EC;
$grape-400: #9B78D9;
$grape-500: #744FC1;
$grape-600: #5939A5;
$grape-700: #41278A;
$grape-800: #2C186F;
$grape-900: #150B42;

$ultramarine-500: rgb(13, 128, 137);
$ultramarine-600: #2C4EDB;
$cyan-500: #39B5D4;
$cyan-600: #289BB8;
$stone-600: #9D9FA1;
$stone-700: #565A5D;
$stone-800: #303132;

/* Dark Mode */
.dark {
  color: $text-white;

  .dash-logo {
    // filter: invert(100%);
  }

  .text-gray-900 {
    color: $text-white;
  }

  .text-gray-500, svg.text-gray-300 {
    color: $text-secondary;
  }

  .icon-weak{
    color: $dark-gray-5;
  }

  .text-weak{
    color: $light-gray-2;
  }

  .text-disabled{
    color: $light-gray-1;
  }

  .bg-white {
    background: $main-bg;
  }

  .bg-gray-100 {
    background: $secondary-bg;
  }

  .card {
    background: $card-bg;

    .card-head {
      background: $card-bg;
    }
  }

  .hover\:bg-gray-100:hover {
    background: $secondary-bg;
    i {
      background: transparent;
    }
  }

  .hover\:text-gray-900:hover {
    color: $text-secondary !important;
  }

  .text-gray-700 {
    color: $text-subtle;
  }

  .crumbs a, .crumbs i {
    background: $main-bg;
    color: $text-subtle;
  }

  @media (min-width: 1024px) {
    .dash-logo-container {
      //background-image: url('/public/img/logo/oct/dark-bats.png') !important;
      background-image: url('/public/img/logo/dec/snowflakes.png') !important;
      background-color: $secondary-bg !important;
    }
  }

  .card-with-button {
    background: $card-bg;

    .card-header {
      background: $card-bg;
    }

    .action-btn-container a {
      background: $card-bg;
      color: $text-secondary;

      i {
        background: $card-bg;
        color: $text-secondary;
      }

      &:hover {
        background: $light-card-bg;
        color: $text-white !important;

        i {
          background: $light-card-bg;
          color: $text-white;
        }
      }
    }
  }

  table {
    background: $secondary-bg;

    a {
      [class^="text-blue-"] {
        color: $link-color;

        &:hover {
          color: $link-hover-color;
        }
      }
    }

    tbody.bg-white tr {
      background: $card-bg;

      &.bg-gray-50 {
        background: $card-bg;
      }

      &.bg-white {
        background: $light-card-bg;
      }

      &:hover {
        background: lighten($light-card-bg, 5%);
      }
    }

    thead {
      background: $secondary-bg;

      th {
        color: $text-white !important;
      }
    }
  }

  .divide-gray-200 {
    tbody, thead {
      border-color: $secondary-bg !important;
    }

    tr, td {
      border-color: $secondary-bg !important;
    }
  }

  .divide-gray-100 {
    div {
      border-color: $secondary-bg !important;
    }
  }

  #search {
    background: $main-bg;
  }

  .bg-gray-300 {
    background: $main-bg;
  }

  .hover\:bg-gray-300:hover, .hover\:bg-gray-50:hover {
    background: $main-bg;
    color: $text-white;

    i {
      background: $main-bg;
      color: $text-white;
    }
  }

  .border-gray-300, .border-gray-200 {
    border-color: $card-bg;
  }

  label label .text-gray-700 {
    color: $text-white;
  }

  input, select, textarea {
    color: #0c0c0c;
  }

  .active-result {
    color: #0c0c0c;
  }

  .bg-blue-600 {
    color: $text-white;
  }

  .card-head {
    border-color: $secondary-bg !important;
  }

  td i.hover\:text-gray-900:hover {
    color: $text-white !important;
  }

  .networks-performace .bg-green-50 {
    color: #1c1e20;
  }

  .tab-pane.bg-gray-200 {
    background: $secondary-bg;
  }

  .bg-dark-card {
    background: $card-bg;
  }

  .bg-dark-card-light {
    background: $light-card-bg;
  }

  .selected-category {
    background: $main-bg;

    h5, .stencil-container {
      background: $card-bg !important;

      .bg-white {
        background: $card-bg !important;
      }

      .stencil-embed {
        background: $secondary-bg !important;
      }
    }
  }

  .dark\:bg-white {
    background: $main-bg !important;
  }

  #dropzone span {
    color: $text-secondary;
  }

  #mobile-sidebar {



    [data-dismiss="sidebar"] svg {
      stroke: white;
    }
  }

  .ui-datepicker-calendar {
    background: white;

    thead {
      background: white;
      span {
        color: black;
      }
    }
  }

  .bg-true-white {
    background: #FFFFFF;
  }

  .bg-dark-secondary {
    background: $secondary-bg;
  }

  .nav {
    .hover\:text-gray-700:hover {
      @apply text-white
    }
  }

  // NEW DARK STYLES

  .btn-clear {
    border-color: #2c2F32;
    background: transparent;
    color: #5A5C62;
  }

  .btn-clear:hover {
    @apply text-white;
    background: transparent;
  }

  .crumb-button:hover {
    color: $text-secondary !important;
    background: $secondary-bg;
    i {
      background: $secondary-bg;
    }
  }

  .btn-left, .btn-mid, .btn-right {
    background: $main-bg;
    border-color: $card-bg;
  }

  .dropdown-menu {
    div {
      border-color: $secondary-bg !important;
    }
  }

  .dropdown-button {
    background: $main-bg;
    color: $text-subtle !important;
    border-color: $card-bg !important;

    &:hover {
      background: $main-bg;
      color: white !important;
    }
  }

  .bg-custom-gradient-1{
    background-image: linear-gradient(180deg, $secondary-bg 2%, $main-bg 100%);
  }
  .bg-custom-gradient-2{
    background-image: linear-gradient(180deg, #41A1DF 2%, #3D67FF 100%);
  }

  // Custom Colors //

  .bg-ultramarine-500 {
    background-color: $ultramarine-500;
  }

  .text-ultramarine-500 {
    color: $ultramarine-500;
  }

  .bg-cyan-500 {
    background-color: $cyan-500;
  }

  .text-cyan-500 {
    color: $cyan-500;
  }

  .text-cyan-600 {
    color: $cyan-600;
  }

  .bg-grape-500 {
    background-color: $grape-500;
  }

  .text-grape-500 {
    color: $grape-500;
  }

  .text-grape-600 {
    color: $grape-600;
  }

  .text-stone-600 {
    color: $stone-600;
  }

  .text-stone-700 {
    color: $stone-700;
  }

  .text-stone-800 {
    color: $stone-800;
  }

  .custom-number-input {
    .num-button, input {
      color: white;
      background: #222;
    }

    .num-button:hover {
      background: #333;
    }

    input:hover {
      color: white;
    }
  }
  // GAM
  .gam-form{
    .radio-btn{
      background: $secondary-bg;
    }
    .form-control{
      max-width: 100%;
    }
  }

  
}

.widget-link {
  color: $light-gray-1;
}

.bg-violet-600 {
  background-color: $grape-600;

  &:hover {
    background-color: $grape-700;
  }
}

// Analytics
.analytics-style{
  .rounded-xl{
    border-radius: 17px !important;
  }
  .ctr-grade{
    background: rgba(29,28,28,0.21);
  }
  table{
    background: #ffffff;
    font-size: 14px;
    width: 100%;
    thead{
      background: #ffffff;
      color: #1273CE !important;
      th{
        font-weight: bold;
        color: #1273CE !important;
        &:first-child{
          text-align: left;
        }
        &:last-child{
          text-align: right;
        }
      }
    }
    tbody{
      tr{
        td{
          &:last-child{
            color: $stone-700;
            text-align: right;
          }
        }
      }
    }
  }
}
  // GAM
  .gam-form{
    .form-control{
      max-width: 100%;
    }
  }
  
  .dropzone{
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    .dz-message{
      color: $light-gray-1 !important;
    }
  }