@import "../stylesheets/components/dark.scss";
@import "../stylesheets/tailwind.scss";

body, h1, h2, h3, h4, h5, h6, a {
    font-family: 'myriad-pro', sans-serif !important;
}

$azure-50: #E7F9FD;
$azure-100: #CCF1FB;
$azure-200: #9BDFF9;
$azure-300: #67C2EF;
$azure-400: #41A1DF;
$azure-500: #0876CB;
$azure-600: #065BAE;
$azure-700: #054492;
$azure-800: #043075;
$azure-900: #021A4B;

$cobalt-50: #E7F9FD;
$cobalt-100: #CCF1FB;
$cobalt-200: #9BDFF9;
$cobalt-300: #67C2EF;
$cobalt-400: #41A1DF;
$cobalt-500: #0876CB;
$cobalt-600: #065BAE;
$cobalt-700: #054492;
$cobalt-800: #043075;
$cobalt-900: #021A4B;

.text-blue-400{color: $azure-400;}
.text-blue-500{color: $azure-500;}
.text-blue-600{color: $azure-600;}
.bg-blue-400{background-color: $azure-400;}
.bg-blue-500{background-color: $azure-500;}
.bg-blue-600{background-color: $azure-600;}

.btn-blue-gradient-1 {
    background-image: linear-gradient(90deg, $azure-400 2%, $cobalt-600 100%);
    border: none;
}

.bg-dark-main {
    background: #151617;
}

.bg-card {
    background: #1C1E20;
}

label {
    color: #9D9FA2;
}

